.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    float:right;
    bottom:20px;
  }
  
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }