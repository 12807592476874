.pagination{
    display: flex;
    justify-content: center;
    padding-top: 100px;
    ul{
      list-style: none;
      padding: 0px;
      display: flex;
  
      li{
        background: #26d493;
        padding: 10px 15px;
        margin-right: 4px;
        border-radius: 4px;
        font-family: sans-serif;
        color: #fff;
        font-size: 1.1rem;
        min-width: 20px;
        text-align: center;
        user-select: none;
  
        &:last-child{
          margin-right: 0px;
        }
  
        &:not(.active):not(.disabled){
          cursor: pointer;
        }
        &:not(.active):not(.disabled):hover{
          background: #26d493
        }
        &:not(.active):not(.disabled):active{
          background: #26d493;
        }
  
        &.active{
          background: #26d493;
        }
        &.disabled{
          background: #26d493;
          pointer-events: none;
        }
      }
    }
  }

@extendbody {
    background: whitesmoke;
    font-family: 'Open Sans', sans-serif;
}

.image-upload {
    position: relative;
    max-width: 405px;
    margin: 50px auto;
    .image-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;
        input {
            display: none;
            + label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #FFFFFF;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all .2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f040";
                    font-family: 'FontAwesome';
                    color: #757575;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .image-preview {
        width: 100%;
        height: 192px;
        position: relative;
       
        border: 6px solid #F8F8F8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        > img{
            width: 100%;
            height: 100%;
           
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}